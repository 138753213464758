const nomes = {
  Aldo: "Aldo Przybysz",
  ALINE: "Aline Milan Farias",
  ANDRÉ: "Andre Luiz Soares",
  SEDIANE: "Sediane Carmem Lunardi Hernandes",
  DANIELA: "Daniela dos Santos de Oliveira",
  MARIANE: "Mariane Kempka",
  LYVIA: "Lyvia Regina Biagi Silva Bertachi",
  FRANCIELE: "Franciele Bonatto",
  FLÁVIA: "Flávia Konowalenko",
  "HERMANO E RENATA": "Hermano Pereira e Renata Luiza Stange Carneiro Gomes",
  RAFAELLA: "Rafaella",
  MARCELA: "Marcela Maier Farias",
  DENISE: "Denise Alves Ramalho",
  BELMIRO: "Belmiro Marcos Beloni",
  MARJORIE: "Marjorie Maria Bellinello",
  PETRÔNIO: "Petrônio Rodrigo Mello Montezuma",
  ROLF: "Rolf de Campos Intema",
  KUMMER: "Rodrigo Kummer",
  SILVIA: "Sílvia do Nascimento Rosa",
  SOLIANE: "Soliane Moreira",

  Álamo: "Álamo Alexandre da Silva Batista",
  Arthur: "Arthur Hirata Bertachi",
  Pecapedra: "Carlos Francisco Pecapedra Souza",
  Denis: "Denis Lucas Silva",
  Diogo: "Diogo Zampieri Montanher",
  "Guilherme da Costa Silva": "Guilherme da Costa Silva",
  Gustavo: "Gustavo de Miranda Saleme Gidrao",
  Hilson: "Hilson Henrique Daum",
  Luciano: "Luciano Ogiboski",
  Rodrigo: "Rodrigo Scoczynski Ribeiro",
  Roni: "Roni Fabio Banaszewski",
  Viviane: "Viviane Chulek",
  Vlademir: "Vlademir Aparecido Freire Junior",
  Carla: "Carla Dantas da Silva",
};

export default nomes;
