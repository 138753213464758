const BlocoE = () => (
  <>
    <h1>Bloco E</h1>

    <video className="video" controls>
      <source src="./videos/Bloco-E-compressed.mp4" type="video/mp4" />
    </video>
  </>
);

export default BlocoE;
