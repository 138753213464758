const Stand = () => (
  <div>
    <h1>Projetos</h1>

    <video className="video" controls>
      <source src="./videos/Projetos-compressed.mp4" type="video/mp4" />
    </video>
  </div>
);

export default Stand;
