const blocoA = () => (
  <>
    <h1>Bloco A</h1>

    <video className="video" controls>
      <source src="./videos/Bloco-A-compressed.mp4" type="video/mp4" />
    </video>
  </>
);

export default blocoA;
