import React from "react";

export default function BlocoF(props) {
  return (
    <>
      <h1>Bloco R</h1>

      <video className="video" controls>
        <source src="./videos/Bloco-R-compressed.mp4" type="video/mp4" />
      </video>
    </>
  );
}
